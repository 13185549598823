<template>
  <b-overlay :show="showOverlay ">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="updateGameService" tag="div">
      <b-modal
          id="modal-delete"
          cancelTitle="Cancel"
          centered
          title="Delete Feature"

          ok-title="Delete"
          @ok.prevent="deleteFeature"
      >
        <div class="row">
          <div class="col-md-12">
            <span>Are you sure you want to delete this product feature ?</span>
          </div>
        </div>
      </b-modal>
      <validation-observer ref="createProduct" tag="div">
        <b-card v-if="fieldDetail" class="data-edit-wrapper">
          <h2>Product Field Detail</h2>
          <b-form class="mt-2">
            <b-row>
              <b-col class="" md="4">
                <b-form-group
                    label="Name"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      ref="fieldName"
                      v-model="fieldDetail.name"
                  />
                </b-form-group>
              </b-col>
              <b-col class="" md="4">
                <b-form-group
                    label="Type Of Fields"
                >
                  <v-select
                      v-model="fieldDetail.productFieldDetailTypeId"
                      :options="fieldModes"
                      :reduce="name => name.value"
                      class="per-page-selector w-75 mx-50"
                      disabled
                      label="name"
                  />

                </b-form-group>
              </b-col>
              <b-col class=" d-flex align-items-center" md="4">
                <b-form-group
                    label=" "
                >
                  <b-form-checkbox
                      id="checkbox-1"
                      v-model="fieldDetail.optional"
                      name="checkbox-1"
                  >
                    Optional
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                    class=" "
                    variant="primary"
                    @click="updateFieldDetail"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </validation-observer>

      <b-card v-if="fieldDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>Product features</h2>
          </b-col>
          <b-col class="my-1" cols="3" md="12">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
            >
              <b-link
                  :to="`/apps/games/game-service-categories/game-service/products/product-field/product-feature/create-product-feature?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${$route.query.productId}&productFieldDetailId=${fieldDetail.productFieldDetailId}`"
              >
                <span class="mr-25 align-middle text-white">Create</span>
              </b-link>

            </b-button>
          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns"
                :items="fieldFeatures"
                :sort-by.sync="sortBy"
                bordered
                class="position-relative"

                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >

              <template #cell(productfieldDetail)="data">
              </template>
              <template #cell(operation)="data">
                <div class="d-flex align-items-center  w-100">
                  <b-link
                      v-if=""
                      :to="`/apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${$route.query.productId}&productFieldDetailId=${fieldDetail.productFieldDetailId}&productFeatureId=${data.item.productFeatureId}`"
                  >
                    <feather-icon
                        class="text-primary"
                        icon="EditIcon"
                        size="20"
                    />
                  </b-link>
                  <span v-b-modal.modal-delete class="cursor-pointer mx-1"
                        @click="setSelectedField(data.item)"
                  >
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
                </div>
              </template>
            </b-table>

          </b-col>
          <b-col cols="12">
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                    class="d-flex align-items-center justify-content-center"
                    cols="12"
                    sm="12"
                >
                  <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
                </b-col>
                <!-- Pagination -->
                <b-col
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    cols="12"
                    sm="6"
                >
                  <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalCount"
                      class="mb-0 mt-1 mt-sm-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>

    </validation-observer>

  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import {
  DeleteProductFieldFeature,
  GetProductFieldDetail,
  GetProductFieldFeaturesByFieldId,
  UpdateProductFieldDetail
} from '@/libs/Api/productField'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-product-field',
  title: 'manage product field',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      totalCount: null,
      perPage: 10,
      selectedField: null,
      sortBy: '',
      currentPage: 1,
      fieldFeatures: null,
      fieldDetail: null,
      currencies: null,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: false
        },
        {
          text: `Manage Product Field`,
          href: `/apps/games/game-service-categories/game-service/products/product-field/manage-product-field?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}&productFieldDetailId=${this.$route.query.productFieldDetailId}`,
          active: true
        },
      ],

      fieldModes: [
        {
          name: 'drop down',
          value: 1
        },
        {
          name: 'radio button',
          value: 2
        },
        {
          name: 'check box',
          value: 3
        },
        {
          name: 'slider',
          value: 4
        },
      ],
      myTableColumns: [
        {
          key: 'productFeatureId',
          label: 'feature id',
          sortable: true
        },
        {
          key: 'name',
          label: 'name',
          sortable: true
        },

        {
          key: 'operation',
          label: 'operation'
        },
      ],
      updateFieldDetailPayload: {
        productFieldId: null,
        productId: null,
        name: '',
        productFieldDetails: []
      }
    }
  },

  async created() {
    await Promise.all([
      this.getFieldDetail(),
      this.getFeaturesByFieldId()
    ])
  },
  watch: {
    currentPage: function (val) {
      this.getFeaturesByFieldId()
    }
  },
  methods: {
    async getFeaturesByFieldId() {
      let _this = this
      _this.showOverlay = true
      let getProductFieldFeaturesByFieldId = new GetProductFieldFeaturesByFieldId(_this)
      let data = {
        productFieldDetailId: _this.$route.query.productFieldDetailId,
        pageNumber: _this.currentPage,
        count: _this.perPage
      }
      getProductFieldFeaturesByFieldId.setParams(data)
      await getProductFieldFeaturesByFieldId.fetch(function (content) {
        _this.showOverlay = false
        _this.fieldFeatures = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    createPayload() {
      this.updateFieldDetailPayload.productId = this.$route.query.productId
      this.updateFieldDetailPayload.productFieldId = this.fieldDetail.productFieldId
      this.updateFieldDetailPayload.productFieldDetails.push({
        productFieldDetailId: this.fieldDetail.productFieldDetailId,
        name: this.fieldDetail.name,
        productFieldDetailType: this.fieldDetail.productFieldDetailTypeId,
        optional: this.fieldDetail.optional
      })
      return this.updateFieldDetailPayload
    },

    async deleteFeature() {
      let _this = this
      _this.showOverlay = true
      let deleteProductFieldFeature = new DeleteProductFieldFeature(_this)
      deleteProductFieldFeature.setParams({ productFeatureId: _this.selectedField.productFeatureId })
      await deleteProductFieldFeature.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `successfully deleted`,
          },
        })
        _this.getFeaturesByFieldId()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setSelectedField(item) {
      this.selectedField = JSON.parse(JSON.stringify(item))
    },
    async updateFieldDetail() {
      let _this = this
      _this.showOverlay = true
      let updateProductFieldDetail = new UpdateProductFieldDetail(_this)
      let data = this.createPayload()
      updateProductFieldDetail.setParams(data)
      await updateProductFieldDetail.fetch(function (content) {
        _this.showOverlay = false
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.updateFieldDetailPayload = {
            productFieldId: null,
            productId: null,
            name: '',
            productFieldDetails: []
          }
          _this.getFieldDetail()
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: content.message,
              title: `error`,
              variant: 'danger',
            },
          })
        }
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    async getFieldDetail() {
      let _this = this
      _this.showOverlay = true
      let getProductFieldDetail = new GetProductFieldDetail(_this)
      let data = {
        productFieldDetailId: _this.$route.query.productFieldDetailId
      }
      getProductFieldDetail.setParams(data)
      await getProductFieldDetail.fetch(function (content) {
        _this.showOverlay = false
        _this.fieldDetail = content.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
  }
}
</script>

<style scoped>

</style>
